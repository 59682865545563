import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQuery} from "../../api";
import {ICreatePickupSpot, IGetAdminsPickupSpot} from "./types";


const pickupSpotTag = 'pickupSpot';
export const adminPickupSpotApi = createApi({
    tagTypes: [pickupSpotTag],
    baseQuery,
    endpoints: (builder) => ({
        getAdminsPickupSpots: builder.query<IGetAdminsPickupSpot[], void>({
            query: () => ({
                method: 'GET',
                url: 'admin/pickup_spots',
            }),
            providesTags: [pickupSpotTag],
        }),
        getPickupSpots: builder.query<IGetAdminsPickupSpot[], void>({
            query: () => ({
                method: 'GET',
                url: 'pickup_spots',
            }),
            providesTags: [pickupSpotTag],
        }),
        createPickupSpot: builder.mutation<void, ICreatePickupSpot>({
            query: (body) => ({
                method: 'POST',
                url: `admin/pickup_spots`,
                body
            }),
            invalidatesTags: [pickupSpotTag],
        }),
        deletePickupSpot: builder.mutation<void, { id: string }>({
            query: ({id}) => ({
                method: 'DELETE',
                url: `admin/pickup_spots/${id}`,
            }),
            invalidatesTags: [pickupSpotTag],
        }),
    }),
    reducerPath: 'adminPickupSpotApi',
});

export const {
    useCreatePickupSpotMutation,
    useDeletePickupSpotMutation,
    useGetAdminsPickupSpotsQuery,
    useGetPickupSpotsQuery
} = adminPickupSpotApi;
