import {
    TypedUseSelectorHook,
    useDispatch as useReduxDispatch, useSelector,
    useSelector as useReduxSelector,
} from 'react-redux';
import {
    createTransform,
    FLUSH,
    PAUSE,
    PERSIST,
    persistReducer,
    persistStore,
    PURGE,
    REGISTER,
    REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {combineReducers, configureStore} from "@reduxjs/toolkit";
import {config} from "./config";
import {adminReservationsApi} from "./admin/reservations/api";
import {dateSlice, dateState} from "./slices/DateSlice";
import {adminBalloonsApi} from "./admin/balloons/api";
import {authApi} from "./admin/auth/api";
import {authSlice, authState} from "./slices/auth/authSlice";
import {adminPilotsApi} from "./admin/pilots/api";
import {adminUsersApi} from "./admin/users/api";
import {adminPickupSpotApi} from "./admin/pickupSpot/pickupSpot";
import {paymentApi} from "./admin/payment/api";
import {adminVouchersApi} from "./admin/vouchers/api";

const persistConfig = {
    key: 'root',
    storage,
    // transforms: [authTransform],
    version: 1,
    // whitelist: [authSlice.name],
};

const rootReducer = combineReducers({
    [adminReservationsApi.reducerPath]: adminReservationsApi.reducer,
    [adminBalloonsApi.reducerPath]: adminBalloonsApi.reducer,
    [adminPilotsApi.reducerPath]: adminPilotsApi.reducer,
    [adminUsersApi.reducerPath]: adminUsersApi.reducer,
    [adminPickupSpotApi.reducerPath]: adminPickupSpotApi.reducer,
    [adminVouchersApi.reducerPath]: adminVouchersApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [paymentApi.reducerPath]: paymentApi.reducer,
    [dateSlice.name]: dateSlice.reducer,
    [authSlice.name]: authSlice.reducer,
});

// @ts-ignore
const persistedReducer = persistReducer(persistConfig, rootReducer,) as typeof rootReducer;

export const store = configureStore({
    devTools: config.enableReduxDevTools,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
            },
        }).concat(
            adminReservationsApi.middleware,
            adminBalloonsApi.middleware,
            authApi.middleware,
            adminPilotsApi.middleware,
            adminUsersApi.middleware,
            adminPickupSpotApi.middleware,
            paymentApi.middleware,
            adminVouchersApi.middleware
        ),
    reducer: persistedReducer,
});

export interface RootState {
    date: dateState;
    auth: authState;
    // other slices...
}

export const persistor = persistStore(store);
export type AppDispatch = typeof store.dispatch;
// export type RootState = ReturnType<typeof store.getState>;

export const useDispatch = () => useReduxDispatch<AppDispatch>();

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

