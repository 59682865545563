
import { createApi } from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../../api";
import {
    AvailableDatesForReservationData,
    BalloonReservation,
    BookReservation,
    GetAvailableDatesForReservation, IBookReservationRes,
    IChangeBalloon,
    IChangeReservation,
    IChangeReservationDate,
    IChangeReservationInsideBalloon,
    IEditReservation,
    ISearchData,
    ISearchReservations,
    ReservationDetails,
    ReservationsWeekData
} from "./types";
import {IBalloonData, MapBalloon} from "../balloons/types";
import {IActivateBalloonsAtDate, IDisableBalloonsAtDate, IGetPilotsAvailableByDate} from "../pilots/types";


export const reservationTag = 'reservation' as string;
export const reservationTagMap = 'reservationMap' as string;

// const reservationTagMap = {type: reservationTag, id: 'map'};
export const adminReservationsApi = createApi({
    tagTypes: [reservationTag, reservationTagMap],
    baseQuery,
    endpoints: (builder) => ({
        getAvailableReservation: builder.query<AvailableDatesForReservationData[], GetAvailableDatesForReservation>({
            query: ({flyKind, total_weight, passengers_count, dateFrom, dateTo}) => ({
                method: 'GET',
                url: `/reservations/available_dates?date_from=${dateFrom}&date_to=${dateTo}&kind=${flyKind}&total_weight=${total_weight}&passengers_count=${passengers_count}`,
            }),
            providesTags: [reservationTag, reservationTagMap],
        }),
        setBookReservation: builder.mutation<IBookReservationRes, BookReservation>({
            query: (body) => ({
                method: 'POST',
                url: `reservations`,
                body
            }),
        }),
        getAdminReservationsByDate: builder.query<BalloonReservation[], {date: string}>({
            query: ({date}) => ({
                method: 'GET',
                url: `/admin/balloon_reservations/balloons_by_day?date=${date}`,
            }),
            providesTags: [reservationTag],
        }),
        getAvailableBalloonsByDate:  builder.query<IBalloonData[], { date: string }>({
            query: ({date}) => ({
                method: 'GET',
                url: `/admin/balloon_reservations/available_balloon_reservations?date=${date}`,
            }),
            providesTags: [reservationTag],
        }),
        getAvailablePilotsByDate: builder.query<IGetPilotsAvailableByDate[], {date: string}>({
            query: ({date}) => ({
                method: 'GET',
                url: `admin/pilots/available_pilots?date=${date}`,
            }),
            providesTags: [reservationTag],
        }),
        disableBalloonsAtDate: builder.mutation<void, IDisableBalloonsAtDate>({
            query: (body) => ({
                method: 'POST',
                url: `admin/balloons/turn_off_all_flights`,
                body: body
            }),
            invalidatesTags: [reservationTag],
        }),
        activateBalloonsAtDate: builder.mutation<void, IActivateBalloonsAtDate>({
            query: (body) => ({
                method: 'POST',
                url: `admin/balloons/turn_on_all_flights`,
                body: body
            }),
            invalidatesTags: [reservationTag],
        }),
        getReservationDetails: builder.query<ReservationDetails, {reservationId: string}>({
            query: ({reservationId}) => ({
                method: 'GET',
                url: `/admin/reservations/${reservationId}/show_more`,
            }),
            providesTags: [reservationTag]
        }),
        changeReservationBetweenBalloons: builder.mutation<void, IChangeReservation>({
            query: ({reservationId,balloonPilotId}) => ({
                method: 'PUT',
                url: `admin/reservations/${reservationId}/change_balloon?balloon_reservation_id=${balloonPilotId}`,
            }),
        }),
        changeBalloonToAnother: builder.mutation<void, IChangeBalloon>({
            invalidatesTags: (result, error) => {
                if (error && error.status === 422) {
                    return [reservationTagMap];
                }
                return [reservationTag, reservationTagMap];
            },
            query: ({balloonPilotIdFrom,balloonPilotIdTo}) => ({
                method: 'PUT',
                url: `admin/balloon_reservations/${balloonPilotIdFrom}/change_balloon?new_balloon_reservation_id=${balloonPilotIdTo}`,
            }),
        }),
        changePilotToAnother: builder.mutation<void, IChangeBalloon>({
            invalidatesTags: [reservationTag],
            query: ({balloonPilotIdFrom,balloonPilotIdTo}) => ({
                method: 'PUT',
                url: `admin/balloon_reservations/${balloonPilotIdFrom}/change_pilot?pilot_id=${balloonPilotIdTo}`,
            }),
        }),
        changeReservationInsideBalloon: builder.mutation<void, IChangeReservationInsideBalloon>({
            query: ({reservationId,position}) => ({
                body: {
                    reservation: {
                        position
                    }
                },
                method: 'PUT',
                url: `admin/reservations/${reservationId}`,
            }),
            invalidatesTags: [reservationTag, reservationTagMap],
        }),
        getBalloonMap: builder.query<MapBalloon[], {id:string}>({
            query: ({id}) => ({
                method: 'GET',
                url: `admin/balloon_reservations/${id}/map_points`,
            }),

            providesTags: [reservationTag, reservationTagMap],
        }),
        getReservationByWeek: builder.query<ReservationsWeekData, {dateTo:string, dateFrom: string}>({
            query: ({dateTo,dateFrom}) => ({
                method: 'GET',
                url: `admin/reservations/reservations_by_date_range?date_from=${dateFrom}&date_to=${dateTo}`,
            }),
        }),
        changeReservationDate: builder.mutation<void, IChangeReservationDate>({
            query: ({reservationId,date, position}) => ({
                method: 'PUT',
                url: `admin/reservations/${reservationId}/change_date?date=${date}&position=${position}`,
            }),
            invalidatesTags: [reservationTag],
        }),
        changeReservationPrivateFly: builder.mutation<void, {reservationId: string}>({
            query: ({reservationId}) => ({
                method: 'PUT',
                url: `admin/reservations/${reservationId}/change_to_private_flight`,
            }),
            invalidatesTags: [reservationTag],
        }),
        editReservation: builder.mutation<void, IEditReservation>({
            query: ({body,id}) => ({
                method: 'PUT',
                url: `admin/reservations/${id}`,
                body
            }),
            invalidatesTags: (result, error) => {
                if (error && error.status === 422) {
                    return [];
                }
                return [reservationTag];
            },
        }),
        deleteReservationPassenger: builder.mutation<void, { id: string }>({
            query: ({id}) => ({
                method: 'DELETE',
                url: `admin/reservations/delete_passenger?passenger_id=${id}`,
            }),
            invalidatesTags: [reservationTag],
        }),
        verifyCard: builder.mutation<void,  {stripe_token: string}>({
            query: (body) => ({
                method: 'POST',
                url: `/verify_card`,
                body
            }),
        }),
        getReservationsExportCSV: builder.query<void, {date: string}>({
            query: ({date}) => ({
                method: 'GET',
                url: `admin/export/export_to_xlsx?date=${date}`,
            }),
        }),
        searchReservations: builder.query<ISearchData[], ISearchReservations>({
            query: ({page,pageSize, filterName, created_at_from, created_at_to, flight_date_from, flight_date_to }) => ({
                method: 'GET',
                url: `/admin/reservations?page[number]=${page}&page[size]=${pageSize}&filter[name]=${filterName}&filter[created_at_from]=${created_at_from}&filter[created_at_to]=${created_at_to}&filter[flight_date_from]=${flight_date_from}&filter[flight_date_to]=${flight_date_to}&export=false`,
            }),
        }),
    }),
    reducerPath: 'adminReservationsApi',
});

export const {
    useGetAvailableReservationQuery,
    useSetBookReservationMutation,
    useGetAdminReservationsByDateQuery,
    useGetAvailablePilotsByDateQuery,
    useGetAvailableBalloonsByDateQuery,
    useLazyGetReservationDetailsQuery,
    useChangeReservationBetweenBalloonsMutation,
    useChangeBalloonToAnotherMutation,
    useChangeReservationInsideBalloonMutation,
    useLazyGetBalloonMapQuery,
    useGetBalloonMapQuery,
    useLazyGetReservationByWeekQuery,
    useChangeReservationDateMutation,
    useChangePilotToAnotherMutation,
    useChangeReservationPrivateFlyMutation,
    useEditReservationMutation,
    useDeleteReservationPassengerMutation,
    useVerifyCardMutation,
    useLazyGetReservationsExportCSVQuery,
    useLazySearchReservationsQuery,
    useDisableBalloonsAtDateMutation,
    useActivateBalloonsAtDateMutation,
} = adminReservationsApi;
