
import { createApi } from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../../api";
import {
    Balloon, IBalloonData,
    IChangeUnavailableDatesBalloon,
    ICreateBalloon,
    IGetAllUnavailableBalloons, IGetFilterUnavailableDatesBalloon, IGetFilterUnavailableDatesBalloonData,
    ITurnOffPrivateFlyBalloon,
    MapBalloon,
} from "./types";
import {
    IActivateBalloonsAtDate,
    IChangeUnavailableDates,
    ICreateUnavailableDatesPilots,
    ICreationPilot,
    IDisableBalloonsAtDate,
    IGetUnavailableDatesPilots
} from "../pilots/types";


const balloonTag = 'balloon';
const balloonUnavailableTag = 'balloonUnavailable'
export const adminBalloonsApi = createApi({
    tagTypes: [balloonTag, balloonUnavailableTag],
    baseQuery,
    endpoints: (builder) => ({
        getBalloons: builder.query<Balloon[], void>({
            query: () => ({
                method: 'GET',
                url: 'admin/balloons',
            }),
            providesTags: [balloonTag],
        }),

        getBalloonsByDate: builder.query<Balloon[], { date: string }>({
            query: ({date}) => ({
                method: 'GET',
                url: `/admin/balloons/available_balloons?date=${date}`,
            }),
        }),
        createBalloon: builder.mutation<void, ICreateBalloon>({
            query: (body) => ({
                method: 'POST',
                url: `admin/balloons`,
                body
            }),
            invalidatesTags: [balloonTag],
        }),
        deleteBalloon: builder.mutation<void, { id: string }>({
            query: ({id}) => ({
                method: 'DELETE',
                url: `admin/balloons/${id}`,
            }),
            invalidatesTags: [balloonTag],
        }),
        turnOffPrivateFlyBalloon: builder.mutation<void, ITurnOffPrivateFlyBalloon>({
            query: ({id, body}) => ({
                method: 'PUT',
                url: `admin/balloons/${id}/change_settings`,
                body
            }),
            invalidatesTags: [balloonTag],
        }),
        createUnavailableDatesBalloon: builder.mutation<void, ICreateUnavailableDatesPilots>({
            query: (body) => ({
                method: 'POST',
                url: `admin/balloons/${body.id}/add_unavailable_date`,
                body: body.body
            }),
            invalidatesTags: [balloonUnavailableTag],
        }),
        getUnavailableDatesBalloon: builder.query<string[], IGetUnavailableDatesPilots>({
            query: ({dateFrom, dateTo, id}) => ({
                method: 'GET',
                url: `admin/balloons/${id}/unavailable_dates?date_from=${dateFrom}&date_to=${dateTo}`,
            }),
            providesTags: [balloonUnavailableTag],
        }),
        getAllUnavailableBalloons: builder.query<IGetAllUnavailableBalloons[], void>({
            query: () => ({
                method: 'GET',
                url: `admin/balloons/all_unavailable_dates`,
            }),
            providesTags: [balloonUnavailableTag],
        }),

        changeUnavailableDates: builder.mutation<void, IChangeUnavailableDatesBalloon>({
            query: (body) => ({
                method: 'PUT',
                url: `admin/balloons/${body.balloonId}/change_unavailable_date`,
                body: body.body
            }),
            invalidatesTags: [balloonUnavailableTag],
        }),
        getFilterUnavailableDatesBalloon: builder.query<IGetFilterUnavailableDatesBalloonData[], IGetFilterUnavailableDatesBalloon>({
            query: ({dateFrom, dateTo, id}) => ({
                method: 'GET',
                url: `admin/balloons/all_unavailable_dates?balloon_id=${id}&date_from=${dateFrom}&date_to=${dateTo}`,
            }),
            providesTags: [balloonUnavailableTag],
        }),
    }),
    reducerPath: 'adminBalloonsApi',
});

export const {
    useGetBalloonsQuery,
    useGetBalloonsByDateQuery,
    useCreateBalloonMutation,
    useDeleteBalloonMutation,
    useCreateUnavailableDatesBalloonMutation,
    useGetUnavailableDatesBalloonQuery,
    useGetAllUnavailableBalloonsQuery,
    useTurnOffPrivateFlyBalloonMutation,
    useChangeUnavailableDatesMutation,
    useGetFilterUnavailableDatesBalloonQuery
} = adminBalloonsApi;
