import React from "react";
import {BrowserRouter, Navigate, Outlet, Route, Routes} from 'react-router-dom';
import {LazyLoadableScreen} from "../utils/navigation";
import {AuthRequired} from "../services/redux/slices/auth/AuthRequired";
const HomeScreen = LazyLoadableScreen(() => import('../screens/Home'));
const LogInScreen = LazyLoadableScreen(() => import('../screens/LogIn'));
const BookingScreen = LazyLoadableScreen(() => import('../screens/Booking'));
const PilotsScreen = LazyLoadableScreen(() => import('../screens/Pilots'));
const BalloonsScreen = LazyLoadableScreen(() => import('../screens/Balloons'));
const PickupSpotsScreen = LazyLoadableScreen(() => import('../screens/PickupSpots'));
const UsersScreen = LazyLoadableScreen(() => import('../screens/Users'));
const AdminBookingScreen = LazyLoadableScreen(() => import('../screens/BookingAdmin'));
const SearchScreen = LazyLoadableScreen(() => import('../screens/SearchScreen'));
const VouchersScreen = LazyLoadableScreen(() => import('../screens/Vouchers'));
const GlobalPaymentScreen = LazyLoadableScreen(() => import('../screens/GlobalPayment'));
const UnavailableBalloonScreen = LazyLoadableScreen(() => import('../screens/UnavailableBalloon'));
const UnavailablePilotScreen = LazyLoadableScreen(() => import('../screens/UnavailablePilot'));

export const Navigation = () => (
    <BrowserRouter>
        <Routes>
            <Route path="/" index element={<BookingScreen />} />
            <Route path="/login" element={<LogInScreen />} />
            <Route path="/payment" element={<GlobalPaymentScreen />} />
            <Route
                path="/admin"
                element={
                <AuthRequired >
                    <Outlet />
                </AuthRequired>}
            >
                <Route index element={<HomeScreen />} />
                <Route path="/admin/pilots" element={<PilotsScreen />} />
                <Route path="/admin/pilots/:id" element={<UnavailablePilotScreen />} />
                <Route path="/admin/balloons" element={<BalloonsScreen />} />
                <Route path="/admin/balloons/:id" element={<UnavailableBalloonScreen />} />
                <Route path="/admin/pickup-spots" element={<PickupSpotsScreen />} />
                <Route path="/admin/users" element={<UsersScreen />} />
                <Route path="/admin/booking" element={<AdminBookingScreen />} />
                <Route path="/admin/search" element={<SearchScreen />} />
                <Route path="/admin/vouchers" element={<VouchersScreen />} />
                <Route path="/admin/*" element={<Navigate to="/admin" />} />
            </Route>
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </BrowserRouter>
)
