
import { createApi } from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../../api";
import {
    IChangeUnavailableDates,
    ICreateUnavailableDatesPilots,
    ICreationPilot,
    IGetAllUnavailablePilots, IGetPilotsAvailableByDate,
    IGetUnavailableDatesPilots,
    IPilot
} from "./types";
import {IGetFilterUnavailableDatesBalloon, IGetFilterUnavailableDatesBalloonData} from "../balloons/types";



const pilotTag = 'pilot';
const pilotUnavailableTag = 'pilotUnavailable';

export const adminPilotsApi = createApi({
    tagTypes: [pilotTag, pilotUnavailableTag],
    baseQuery,
    endpoints: (builder) => ({
        getPilots: builder.query<IPilot[], void>({
            query: () => ({
                method: 'GET',
                url: 'admin/pilots',
            }),
            providesTags: [pilotTag],
        }),

        createPilot: builder.mutation<void, ICreationPilot>({
            query: (body) => ({
                method: 'POST',
                url: `admin/pilots`,
                body
            }),
            invalidatesTags: [pilotTag],
        }),
        deletePilot: builder.mutation<void, { id: string }>({
            query: ({id}) => ({
                method: 'DELETE',
                url: `admin/pilots/${id}`,
            }),
            invalidatesTags: [pilotTag],
        }),
        createUnavailableDatesPilot: builder.mutation<void, ICreateUnavailableDatesPilots>({
            query: (body) => ({
                method: 'POST',
                url: `admin/pilots/${body.id}/add_unavailable_date`,
                body: body.body
            }),
            invalidatesTags: [pilotUnavailableTag],
        }),
        getUnavailableDatesPilot: builder.query<string[], IGetUnavailableDatesPilots>({
            query: ({dateFrom, dateTo, id}) => ({
                method: 'GET',
                url: `admin/pilots/${id}/unavailable_dates?date_from=${dateFrom}&date_to=${dateTo}`,
            }),
            providesTags: [pilotUnavailableTag],
        }),
        getAllUnavailablePilots: builder.query<IGetAllUnavailablePilots[], void>({
            query: () => ({
                method: 'GET',
                url: `admin/pilots/all_unavailable_dates`,
            }),
            // providesTags: [pilotUnavailableTag],
        }),
        changeUnavailableDatesPilots: builder.mutation<void, IChangeUnavailableDates>({
            query: (body) => ({
                method: 'PUT',
                url: `admin/pilots/${body.pilotId}/change_unavailable_date`,
                body: body.body
            }),
            invalidatesTags: [pilotUnavailableTag],
        }),
        getFilterUnavailableDatesPilot: builder.query<IGetFilterUnavailableDatesBalloonData[], IGetFilterUnavailableDatesBalloon>({
            query: ({dateFrom, dateTo, id}) => ({
                method: 'GET',
                url: `admin/pilots/all_unavailable_dates?pilot_id=${id}&date_from=${dateFrom}&date_to=${dateTo}`,
            }),
            providesTags: [pilotUnavailableTag],
        }),
    }),
    reducerPath: 'adminPilotsApi',
});

export const {
    useGetPilotsQuery,
    useCreatePilotMutation,
    useDeletePilotMutation,
    useCreateUnavailableDatesPilotMutation,
    useGetUnavailableDatesPilotQuery,
    useGetAllUnavailablePilotsQuery,
    useChangeUnavailableDatesPilotsMutation,
    useGetFilterUnavailableDatesPilotQuery
} = adminPilotsApi;
