import {createSlice, Slice, SliceCaseReducers} from "@reduxjs/toolkit";
import {RootState} from "../../store";



export const name = 'auth';

export type userRole = 'super_admin' | 'admin';

export interface authState {
    accessToken: string;
    role: userRole;
}

const initialState: authState = {
    accessToken: '',
    role: 'super_admin'
};

export const authSlice: Slice<
    authState,
    SliceCaseReducers<authState>
> = createSlice<authState, SliceCaseReducers<authState>>({
    name,
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.accessToken = action.payload;
        },
        setRole: (state, action) => {
            state.role = action.payload;
        },
    },
});

export const {
    setToken,
    setRole
} = authSlice.actions;

export const selectToken = (state: RootState) =>
    state.auth.accessToken;

export const selectRole = (state: RootState) =>
    state.auth.role;

export const selectIsAuthorized = (state: RootState) =>
    Boolean(selectToken(state));
