
import { createApi } from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../../api";
import {UserAuthData, UserLigIn} from "./types";


const balloonTag = 'balloon'
export const authApi = createApi({
    baseQuery,
    endpoints: (builder) => ({
        logIn: builder.mutation<UserAuthData, UserLigIn>({
            query: (body) => ({
                body,
                method: 'POST',
                url: '/admin/sign_in',
            }),
        }),

    }),
    reducerPath: 'authApi',
});

export const {
    useLogInMutation
} = authApi;
