import {Suspense, SuspenseProps, lazy, FC} from 'react';
import {LinearProgress} from "@mui/material";
const Loadable = (Component: any, fallback: SuspenseProps['fallback']) => (props: any) =>
        (
            <Suspense fallback={fallback}>
                <Component {...props} />
            </Suspense>
        );
const LoadingScreen: FC = () => {
    return <LinearProgress />;
};

export const LazyLoadableScreen = (screen: () => any) =>
    Loadable(lazy(screen), <LoadingScreen />);
