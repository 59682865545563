import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQuery} from "../../api";
import {IPayCardData, IPayVoucher, ISaveCard} from "./types";




export const paymentApi = createApi({
    baseQuery,
    endpoints: (builder) => ({
        payWithCard: builder.mutation<void, IPayCardData>({
            query: (body) => ({
                method: 'POST',
                url: `${body.reservationId}/pay_with_card`,
                body: body.data
            }),
        }),
        checkCloverToken: builder.query<{token_expired: boolean}, void>({
            query: () => ({
                method: 'GET',
                url: 'admin/clover/check_token',
            }),
        }),
        activeCloverToken: builder.query<{clover_url: string}, void>({
            query: () => ({
                method: 'GET',
                url: 'admin/clover/start_auth',
            }),
        }),
        payWithVoucher: builder.mutation<void, IPayVoucher>({
            query: (body) => ({
                method: 'POST',
                url: `pay_with_voucher`,
                body: body
            }),
        }),
    }),
    reducerPath: 'paymentApi',
});

export const {
    usePayWithCardMutation,
    useCheckCloverTokenQuery,
    useLazyActiveCloverTokenQuery,
    usePayWithVoucherMutation
} = paymentApi;
