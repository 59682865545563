
import { createApi } from '@reduxjs/toolkit/query/react';
import {baseQuery} from "../../api";
import {IAddUser, IUser} from "./types";


const userTag = 'user';

export const adminUsersApi = createApi({
    tagTypes: [userTag],
    baseQuery,
    endpoints: (builder) => ({
        getUsers: builder.query<IUser[], void>({
            query: () => ({
                method: 'GET',
                url: `admin/users`,
            }),
            providesTags: [userTag],
        }),
        deleteUser: builder.mutation<void, { id: string }>({
            query: ({id}) => ({
                method: 'DELETE',
                url: `admin/users/${id}`,
            }),
            invalidatesTags: [userTag],
        }),
        addUser: builder.mutation<void, IAddUser>({
            query: (body) => ({
                method: 'POST',
                url: `admin/users`,
                body
            }),
            invalidatesTags: [userTag],
        }),
    }),
    reducerPath: 'adminUsersApi',
});

export const {
    useGetUsersQuery,
    useDeleteUserMutation,
    useAddUserMutation
} = adminUsersApi;
