import {createSlice, Slice, SliceCaseReducers} from "@reduxjs/toolkit";
import {RootState} from "../store";
import moment from "moment";
import {RangeType} from "../../../components/Calendar";


export const name = 'date';

export interface dateState {
   today: string;
   todayRedirect: {redirect: boolean, date: string};
   dateFrom: string;
   dateTo: string;
   type: RangeType;
   disableToday: boolean;
}

const initialState: dateState = {
    today: moment().toISOString(),
    todayRedirect: {redirect: false, date: moment().toISOString()},
    dateFrom: moment().toISOString(),
    dateTo: moment().add(6, 'days').toISOString(),
    type: 'day',
    disableToday: false
};

export const dateSlice: Slice<
    dateState,
    SliceCaseReducers<dateState>
> = createSlice<dateState, SliceCaseReducers<dateState>>({
    name,
    initialState,
    reducers: {
        setToday: (state, action) => {
            state.today = action.payload || moment().toISOString();
        },
        setTodayRedirect:(state, action) => {
            state.todayRedirect = action.payload || { redirect: false, date: moment().toISOString() };
        },
        setDateTo: (state, action) => {
            state.dateTo = action.payload || moment().toISOString();
        },
        setDateFrom: (state, action) => {
            state.dateFrom = action.payload || moment().add(6, 'days').toISOString();
        },
        setType: (state, action) => {
            state.type = action.payload || 'day';
        },
        setDisableToday: (state, action) => {
            state.disableToday = action.payload || false;
        },
    },
});

export const {
    setToday,
    setTodayRedirect,
    setDateTo,
    setDateFrom,
    setType,
    setDisableToday
} = dateSlice.actions;

export const selectToday = (state: RootState) =>
    state.date.today;

export const selectTodayRedirect = (state: RootState) =>
    state.date.todayRedirect;
export const selectDateTo = (state: RootState) =>
    state[name].dateTo;

export const selectDateFrom = (state: RootState) =>
    state[name].dateFrom;

export const selectType = (state: RootState) =>
    state[name].type;

export const selectDisableToday = (state: RootState) =>
    state[name].disableToday;
