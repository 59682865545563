import {FC, ReactNode} from "react";
import {Provider} from "react-redux";
import {persistor, store} from "./store";
import {PersistGate} from "redux-persist/integration/react";

interface Props {
    children: ReactNode;
}

export const ReduxProvider: FC<Props> = ( {children})  => {
    return (
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                {children}
            </PersistGate>
        </Provider>
    )
}
