import {createApi} from "@reduxjs/toolkit/dist/query/react";
import {baseQuery} from "../../api";
import {ICreateVoucher, IUpdateVoucher, IVoucherListItem} from "./types";



const voucherTag = 'voucherTag';
export const adminVouchersApi = createApi({
    tagTypes: [voucherTag],
    baseQuery,
    endpoints: (builder) => ({
        getVouchersList: builder.query<IVoucherListItem[], void>({
            query: () => ({
                method: 'GET',
                url: 'admin/vouchers',
            }),
            providesTags: [voucherTag],
        }),
        createVoucher: builder.mutation<void, ICreateVoucher>({
            query: (body) => ({
                method: 'POST',
                url: `admin/vouchers`,
                body
            }),
            invalidatesTags: [voucherTag],
        }),
        updateVoucher: builder.mutation<void, IUpdateVoucher>({
            query: ({body, id}) => ({
                method: 'PUT',
                url: `admin/vouchers/${id}`,
                body
            }),
            invalidatesTags: [voucherTag],
        }),
        deleteVoucher: builder.mutation<void, { id: string }>({
            query: ({id}) => ({
                method: 'DELETE',
                url: `admin/vouchers/${id}`,
            }),
            invalidatesTags: [voucherTag],
        }),
    }),
    reducerPath: 'adminVouchersApi',
});

export const {
    useCreateVoucherMutation,
    useDeleteVoucherMutation,
    useGetVouchersListQuery,
    useUpdateVoucherMutation
} = adminVouchersApi;
