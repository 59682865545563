import React from 'react';
import {CssBaseline, ThemeProvider} from "@mui/material";
import {theme} from "./services/theme/theme";
import {Navigation} from "./navigation";
import {ReduxProvider} from "./services/redux/Provider";

function App() {
  return (
        <ReduxProvider>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Navigation />
            </ThemeProvider>
        </ReduxProvider>
  );
}

export default App;
