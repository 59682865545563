
import {config} from "./config";
import {fetchBaseQuery} from "@reduxjs/toolkit/query";
import {RootState} from "./store";


export const baseQuery = fetchBaseQuery({
    baseUrl: config.apiUrl,
    prepareHeaders: (headers, api) => {
        const token = (api.getState() as RootState).auth.accessToken;

        if (token) {
            headers.set('Authorization', `Bearer ${token}`);
        }

        return headers;
    },
});
