import type { FC } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import {selectIsAuthorized} from "./authSlice";
import {useAppSelector} from "../../store";



export const AuthRequired: FC<any> = ({ children }) => {
    const isAuthorized = useAppSelector(selectIsAuthorized);

    const location = useLocation();

    return isAuthorized ?
            children
        : (
        <Navigate to="/login" state={{ from: location }} replace />
    );
};
